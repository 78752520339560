import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  setHeroIntShoppingFormData,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import TabPanel, {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import FormElement from '@shipae/components-sandbox/component/form-element';
import Input from '@shipae/components-sandbox/component/input';
/*
import Select, {
  ValueType,
} from '@shipae/components-sandbox/component/select';
*/
import { PrimaryButton } from '@shipae/components-sandbox/component/buttons';

import { useTranslation } from 'src/views/misc/localization';
import SmartLink from 'components/smart-link';
import {
  Title,
  PanelTheme,
  FormElementTheme,
} from '../styles';
import {
  UserInput,
  InputThemeEx,
  // SelectThemeEx,
} from './styles';

/*
const countries = [
  { value: 'KW', label: '🇰🇼 Kuwait' },
  { value: 'SA', label: '🇸🇦 Saudi Arabia' },
  { value: 'AE', label: '🇦🇪 United Arab Emirates' },
];
*/

type InternationalShoppingProps = {
  id: string,
  title: string,
  PanelContainer?: React.FC<TabPanelPropsType>,
};
const InternationalShopping: React.FC<InternationalShoppingProps> = ({
  id,
  title,
  PanelContainer = TabPanel,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    heroIntShoppingFormData: formData,
  } = useSelector(getHero);
  const {
    email,
    emailValidationError,
    // country,
    freeAddressUrl,
    allowGetFreeAddress,
  } = formData;

  const handleEmailChange = (inpt: string) => dispatch(
    setHeroIntShoppingFormData({
      ...formData,
      email: inpt || '',
    }),
  );
  /*
  const handleCountryChange = (option?: ValueType) => {
    dispatch(setHeroIntShoppingFormData({
      ...formData,
      country: option,
    }));
  };
  */

  return (
    <PanelContainer id={id} theme={PanelTheme}>
      <Title>{title}</Title>
      <UserInput>
        <FormElement
          label={t('email')}
          hasValue={!!email}
          error={t(emailValidationError || '')}
          theme={FormElementTheme}
          required
        >
          <Input
            type="email"
            onChange={handleEmailChange}
            error={!!emailValidationError}
            theme={InputThemeEx}
          />
        </FormElement>
        {/* disable inputs
        <FormElement
          label={t('country')}
          hasValue={!!country}
          theme={FormElementTheme}
          required
        >
          <Select
            value={country}
            options={countries}
            onChange={handleCountryChange}
            theme={SelectThemeEx}
          />
        </FormElement>
        */}
      </UserInput>
      <PrimaryButton
        href={freeAddressUrl}
        disabled={allowGetFreeAddress}
        linkComponent={SmartLink}
      >
        {t('getFreeAddresses')}
      </PrimaryButton>
    </PanelContainer>
  );
};

export default InternationalShopping;
