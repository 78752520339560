import styled from 'styled-components';
import { InputTheme, SelectTheme } from '../styles';

export const UserInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2.4rem 0 1.2rem 0;
`;

export const InputThemeEx = {
  ...InputTheme,
  Main: styled(InputTheme.Main)`
    margin-right: 1.6rem;
    width: 39.6rem;
  `,
};

export const SelectThemeEx = {
  ...SelectTheme,
  Main: styled(SelectTheme.Main)`
    margin-right: 1.6rem;
    width: 39.6rem;
  `,
};
